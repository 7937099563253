body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

figure {
  margin: 0;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("./assets/fonts/Roboto/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Black";
  src: url("./assets/fonts/Roboto/Roboto-Black.ttf");
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("./assets/fonts/Roboto/Roboto-Medium.ttf");
}

p,
input {
  font-family: "Roboto-Regular";
  font-size: 15px;
  margin: 0;
}

input {
  font-size: 16px !important;
}

button {
  padding: 11px 20px;
  border-radius: 100px;
  background: #323946;
  color: white;
  border: none;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  outline: none;
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: white;
  box-shadow: 0 0 0 30px white inset !important;
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
